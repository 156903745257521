<template>
  <div class="randomExaminationBg">
    <div>
      <el-card>
        <h3 class="randomRxam">试卷信息：</h3>
        <div class="randomMr">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="试卷标题" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入试卷标题"></el-input>
            </el-form-item>
            <el-form-item label="所属课程" prop="region">
              <el-cascader
                  v-model="ruleForm.platformCourseId"
                  :options="majorListArr"
                  clearable
                  ></el-cascader>
            </el-form-item>
            <el-form-item label="选择题型" prop="type">
              <el-select v-model="ruleForm.type">
                <el-option
                    v-for="item in synthesizeQuestionsTypeArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.type">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目数量" prop="number">
              <el-input v-model="ruleForm.number" placeholder="请输入数量"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="startRandoeExam" @click="startDoExercise">
          <el-button type="primary">开始做题</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {majorList, studentTemplateSave, subjectList} from "@/api";
  export default{
    data(){
      return{
        majorListArr:[],
        synthesizeQuestionsTypeArr:[
          {id:1,name:"单项选择题",type:'SC'},
          {id:2,name:"多项选择题",type:'MC'},
          {id:3,name:"判断题",type:'TF'},
          {id:4,name:"填空题",type:'FB'},
          {id:5,name:"简答题",type:'SAQ'},
          {id:6,name:"阅读理解",type:'RC'},
          {id:7,name:"完形填空",type:'CT'},
        ],
        ruleForm: {
          name: '',
          type:'',
          number:'',
          platformCourseId:''
        },
        rules: {
          name: [
            { required: true, message: '请输入试卷标题', trigger: 'blur' },
          ],
          number:[
            {required: true, message: '请输入数量', trigger: 'blur'}
          ]
        }
      }
    },
    methods:{
      // 专业列表
      getMajorList(){
        majorList().then(res=>{
          let arr=res.data.records;
          arr.forEach(item=>{
            item.label=item.name;
            item.value=item.id;
            item.children=[]
            subjectList({collegeEntranceMajorId:item.id}).then(res=>{
              res.data.records.forEach(item1=>{
                if(item.id==item1.collegeEntranceMajorId){
                  item.children.push(item1);
                }
              })
              item.children.forEach(item2=>{
                item2.label=item2.name;
                item2.value=item2.id;
              })
            });
          })
          this.majorListArr=arr;
        })
      },
    //  开始做题
      startDoExercise(){
        if(!this.ruleForm.name){
          this.$message('试卷标题不可为空');return
        }
        if(!this.ruleForm.platformCourseId){
          this.$message('所属课程不可为空');return
        }
        if(!this.ruleForm.type){
          this.$message('题型不可为空');return
        }
        if(!this.ruleForm.number){
          this.$message('题目数量不可为空');return
        }
        let userInfo=JSON.parse(localStorage.getItem("userInfo"));
        let data={
          createType:1,
          testPaperType:1,
          name:this.ruleForm.name,
          randomQueryExercisesVo:{
            collegeEntranceMajorId:this.ruleForm.platformCourseId[0],
            collegeEntranceSubjectId:this.ruleForm.platformCourseId[1],
            exercisesType:this.ruleForm.type,
            randomNumber:this.ruleForm.number
          }
        }
        studentTemplateSave(data).then(res=>{
          if(res.code==0){
            sessionStorage.setItem('examArrDetail',JSON.stringify(res.data))
            let routeUrl = this.$router.resolve({
              path: "/answerExam",
              query:{id:res.data.id,userId:userInfo.id,examTypeShow:2,random:1}
            });
            window.open(routeUrl.href);

          }else if(res.code=='100128'){
            this.$message('您没有操作权限');
          }else{
            this.$message('组卷失败')
          }
        })
      },
    },
    mounted() {
      document.documentElement.scrollTop = 0;
      this.$other.$emit('setIndexNav',3);
      this.getMajorList()
    }
  }
</script>
<style>
  .randomExaminationBg{
    padding-bottom: 200px;
    background: #F7F7F7;
    padding-top: 150px;
  }
  .randomExaminationBg .el-cascader{
    width: 596px;
  }
  .randomExaminationBg .el-select{
    width: 596px;
  }
  .randomExaminationBg>div{
    width: 1200px;
    margin: 0 auto;
  }
  .randomMr{
    margin: 0 auto;
    margin-top:30px;
    width: 60%;
  }
  .randomRxam{
    padding: 30px 0 0 159px;
    font-size: 18px;
  }
  .startRandoeExam{
    text-align: end;
    padding: 50px 0;
  }
</style>
