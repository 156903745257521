<template>
  <div>
    <div >
      <p>
        <span style="color: red">{{ exercisesListIndex }}. </span>
        <span style="color: red" v-if="exercisesARR.scoreNumber">({{ exercisesARR.scoreNumber }}分)：</span>
        <span style="font-size: 16px;line-height: 24px">{{ exercisesARR.exercisesTitle }}</span>
      </p>
      <div class="exercisesPding">
        <div v-for="(item,index) in exercisesARR.exercisesBody" :key="index" >
          <exercisesSC v-if="item.exercisesType=='SC'" @evaluateData="evaluateData" ref="std" :exercisesInfo="item" :exercisesListIndex="index+1" :openType="4" :isShowRightAndAnalysis="ShowRightAndAnalysis"
                       :isShowTeacherCorrection="ShowTeacherCorrection"
                       :isShowTeacherMCDecide="ShowTeacherMCDecide"
                       :isShowTeacherMCDecideScore="ShowTeacherMCDecideScore" @pushData="receiveSubComData">
          </exercisesSC>
          <exercisesMC v-if="item.exercisesType=='MC'" @evaluateData="evaluateData" ref="std" :exercisesInfo="item" :exercisesListIndex="index+1" :openType="4" :isShowRightAndAnalysis="ShowRightAndAnalysis"
                       :isShowTeacherCorrection="ShowTeacherCorrection"
                       :isShowTeacherMCDecide="ShowTeacherMCDecide"
                       :isShowTeacherMCDecideScore="ShowTeacherMCDecideScore" @pushData="receiveSubComData">
          </exercisesMC>
          <exercisesFB v-if="item.exercisesType=='FB'" @evaluateData="evaluateData" ref="std" :exercisesInfo="item" :exercisesListIndex="index+1" :openType="4" :isShowRightAndAnalysis="ShowRightAndAnalysis"
                       :isShowTeacherCorrection="ShowTeacherCorrection"
                       :isShowTeacherDecide="ShowTeacherDecide"
                       :isShowTeacherMCDecideScore="ShowTeacherMCDecideScore" @pushData="receiveSubComData">
          </exercisesFB>
          <exercisesSAQ v-if="item.exercisesType=='SAQ'" @evaluateData="evaluateData" ref="std" :exercisesInfo="item" :exercisesListIndex="index+1" :openType="4" :isShowRightAndAnalysis="ShowRightAndAnalysis"
                        :isShowTeacherCorrection="ShowTeacherCorrection"
                        :isShowTeacherMCDecide="ShowTeacherMCDecide"
                        :isShowTeacherMCDecideScore="ShowTeacherMCDecideScore" @pushData="receiveSubComData">
          </exercisesSAQ>
          <exercisesTF v-if="item.exercisesType=='TF'" @evaluateData="evaluateData" ref="std" :exercisesInfo="item" :exercisesListIndex="index+1" :openType="4" :isShowRightAndAnalysis="ShowRightAndAnalysis"
                       :isShowTeacherCorrection="ShowTeacherCorrection"
                       :isShowTeacherMCDecide="ShowTeacherMCDecide"
                       :isShowTeacherMCDecideScore="ShowTeacherMCDecideScore" @pushData="receiveSubComData">
          </exercisesTF>
        </div>
        <div v-if="exercisesARR.resultScore" class="exerciseScoreFlex">
          <p>试题总得分:</p>
          <el-input v-model="exercisesARR.resultScore" ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {formatExercisesInfo} from "@/utils/userCenterUtil";
  import exercisesFB from "@/components/exercisesList/exercisesFB";
  import exercisesMC from "@/components/exercisesList/exercisesMC";
  import exercisesSAQ from "@/components/exercisesList/exercisesSAQ";
  import exercisesSC from "@/components/exercisesList/exercisesSC";
  import exercisesTF from "@/components/exercisesList/exercisesTF";

  export default {
    props:{
      //习题数据
      exercisesInfo: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
      exercisesListIndex: {
        type: Number,
        default: 1
      },
      openType:{
        type:Number,
        required: true//必传，1作业，2考试
      },
      //是否显示答案与解析,默认false
      isShowRightAndAnalysis:{
        type:Boolean,
        default(){
          return false;
        }
      },
      //是否显示教师批语,默认false
      isShowTeacherComments:{
        type:Boolean,
        default(){
          return false;
        },
      },

    },
    components:{
      exercisesFB,
      exercisesMC,
      exercisesSAQ,
      exercisesSC,
      exercisesTF
    },
    data(){
      return{
        exercisesARR:{},
        ShowRightAndAnalysis:false,
        ShowTeacherCorrection:false,
        ShowTeacherMCDecide:false,
        ShowTeacherMCDecideScore:false,
        ShowTeacherDecide:false,
        exerciseData:{},
        itemResultEvaluate:'',
        itemResultScore:'',
        mResult:'',
        exercisesBody:[],
      }
    },
    methods:{
      //  数组去重
      unique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.uid) && res.set(arr.uid, 1));
      },
      receiveSubComData(){
        this.exercisesARR.exercisesListIndex = this.exercisesListIndex;
        this.$emit('pushData', this.exercisesARR)
      },
      getScoreData() {
        return {item: this.exercisesInfo, itemResultScore: this.itemResultScore}
      },
      getCorrection(){
        return {item: this.exercisesInfo, exercisesBody: this.exercisesBody}
      },
      evaluateData(val){
        if(val){
          this.exercisesBody.push(val);
          this.exercisesBody= this.unique(this.exercisesBody);
          if(val.resultScore){
            this.itemResultScore=val.resultScore;
          }
        }

      }
    },
    computed: {
    },
    watch:{

    },
    created() {
      this.evaluateData()
    },
    mounted() {
      if(typeof(this.exercisesInfo.exercisesBody)=='string'){
        this.exercisesInfo.exercisesBody=JSON.parse(this.exercisesInfo.exercisesBody);
      }
      this.exercisesARR=this.exercisesInfo;
      if(this.$route.name!=='answerExam'){
        this.ShowRightAndAnalysis=true;
        this.ShowTeacherCorrection=true
        this.ShowTeacherMCDecide=true
        this.ShowTeacherMCDecideScore=true
        this.ShowTeacherDecide=true
      }
      if(this.exercisesARR){
        if(this.exercisesARR.exercisesType=='RC'||this.exercisesARR.exercisesType=='CT'){
          if(this.exercisesARR.wSubmit=='1'){
            this.ShowRightAndAnalysis=true;
            this.ShowTeacherMCDecideScore=true
          }
        }
        // this.exercisesARR.exercisesBody.forEach(item=>{
        //   console.log(item,'item5555555')
        //   if(this.$route.name=='answerExam'&&item.analysis&&item.mResult){
        //     this.ShowRightAndAnalysis=true;
        //     this.ShowTeacherMCDecideScore=true
        //   }})

      }
      if(this.$route.name=='topicList'){
        this.ShowRightAndAnalysis=true;
        this.ShowTeacherCorrection=false
        this.ShowTeacherMCDecide=false
        this.ShowTeacherMCDecideScore=false
        this.ShowTeacherDecide=false
      }
    }
  }
</script>
<style>
  .exercisesPding{
    padding: 24px;
  }
  .exerciseScoreFlex{
    display: flex;
  }
  .exerciseScoreFlex>p{
    width: 100px;
    color: red;
    margin: 14px 0;
  }
</style>
