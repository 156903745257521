<template>
  <div class="certificateVideoBg">
    <div class="certificateVideo">
      <video></video>
      <div class="certificateMenuFlex">
        <p>课程目录</p>
        <el-menu

            default-active="2"
            class="el-menu-vertical-demo "
            text-color="#333"
            active-text-color="#409EFF">
          <el-submenu index="1">
            <template slot="title">
              <span>导航一</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">
                <div class="certificatelockFlex">
                  <div>
                    <i class="el-icon-video-play"></i>
                    <span>看到的是朝须</span>
                  </div>
                  <img class="certificateLock" src="images/lock.png">
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </div>

    <div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style>
@import "../../../public/css/certificate.css";
</style>