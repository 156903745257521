<template>
  <div class="zhengshumr">
    <div class="certificatePadding">
      <div>
        <img class="certificateImgMr" src="images/certificateImg.png"/>
        <div class="certificateContent">
          <div>互联网营销师-初级</div>
          <div>
            本课程是一门依照高等职业教育培养目标与新媒体行业企业实际需求设置地专业核心课，主要面向电子商务类，市场营销类，新媒体运营类，新闻传播类等学科实现技能提升与知识更新。
          </div>
          <div>
            <span>课程时长：128分钟</span>
            <span>授课老师：小雪</span>
            <span>学习人数：384</span>
          </div>
          <div>
            <span>售价：</span>
            <span>¥2980</span>
          </div>
          <div class="certificateBtnFlex">
            <el-button type="primary">立即购买</el-button>
            <div>
              <i class="el-icon-star-off collectIcon"></i>
              <span>收藏</span>
            </div>
            <div>
              <i class="el-icon-share collectIcon"></i>
              <span>分享</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-tabs v-model="activeName" class="certificateWd">
          <el-tab-pane label="课程介绍" name="first">
            <img src="">
          </el-tab-pane>
          <el-tab-pane label="课程目录" name="second">
            <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                text-color="#333"
                active-text-color="#409EFF">
              <el-submenu index="1">
                <template slot="title">
                  <span>导航一</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">
                    <div class="certificatelockFlex">
                      <div>
                        <i class="el-icon-video-play"></i>
                        <span>看到的是朝须</span>
                      </div>
                      <img class="certificateLock" src="images/lock.png">
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-tab-pane>
          <el-tab-pane label="测试" name="test">
            <div class="certificatetestWd">
              <p class="certificatetestFlex">
                <span>互联网营销师-初级练习题库</span>
                <i class="el-icon-arrow-right"></i>
              </p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="评论" name="third">
            <div>
              <div class="certificateCommentFlex">
                <el-input v-model="commentInput" placeholder="请输入内容"></el-input>
                <el-button type="primary">发布</el-button>
              </div>
              <div>
                <div class="certificateCommentAvartar">
                  <img src="images/tacitlyAvatar.png">
                  <div>
                    <p>小智同学</p>
                    <p>每天进步一点点，讲的真的很细节！</p>
                  </div>
                </div>
                <div class="certificateContentReplay">
                  <span>李同学</span>
                  <span>回复：孵化基地开工蝴蝶谷</span>
                </div>
                <div class="certificateReplyFlex">
                  <span>回复</span>
                  <span>|</span>
                  <span>展开</span>
                </div>
                <div class="repleyRelease">
                  <el-input v-model="commentInput" placeholder="请输入内容"></el-input>
                  <el-button type="primary">发布评论</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'third',
      commentInput: ""
    }
  }
}
</script>
<style>
@import "../../../public/css/certificate.css";
</style>