<template>
  <div class="certificateMr">
    <img src="images/certificateBanner.png" @click="dialogVisible = true"/>
    <div class="certificateFlex">
      <div class="certificateShadow">
        <div v-for="(item,index) in certificateArry" :key="index" >
          <p class="certificatePadding">{{item}}</p>
        </div>
        <div class="certificateCenter" v-if="this.toCertificateArry.length>4" @click="showAll=!showAll">
          <img  class="scoreImg" :class="showAll?'rotateImg':''" src="images/certificateMore.png"/>
        </div>
      </div>
      <div class="certificateFlexMoney">
        <div class="certificateMoney" v-for="i in 3" @click="goCourList">
          <img class="certificateMoneyimg" src="images/certificateImg.png"/>
          <div>
            <span>¥2980</span>
            <span>教师：小雪</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
      <img class="enrollIcon" src="images/enrollIcon.png"/>
      <p class="enrollSystem">报名系统</p>
      <div class="enrollIconFlex">
        <div>
          <div>
            <el-input  placeholder="姓名"></el-input>
          </div>
          <div>
            <el-input  placeholder="姓名"></el-input>
          </div>
          <div>
            <el-input  placeholder="姓名"></el-input>
          </div>
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div>
            <el-button class="enrollsubmit" type="primary">提交</el-button>
          </div>
        </div>
        <img src="images/enrollBg.png">
      </div>
      <span slot="footer" class="dialog-footer">
<!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
        <!--          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
        </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      toCertificateArry:["跨境电子商务师","心理咨询师","公共营养师","健康管理师","跨境电子商务师","心理咨询师","公共营养师","健康管理师"],
      showAll:false,
      dialogVisible: false,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  methods:{
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // 课程列表
    goCourList(){
      this.$router.push('/certificateCourse')
    }
  },
  mounted() {
  },
  computed:{
    certificateArry(){
      if(this.showAll==false){
        let showList=[];
        if(this.toCertificateArry.length>4){
          for(var i=0;i<4;i++){
            showList.push(this.toCertificateArry[i])
          }
        }else{
          showList=this.toCertificateArry
        }
        return showList
      }else{
        return this.toCertificateArry
      }
    },
  }
}
</script>
<style scoped>
@import "../../../public/css/certificate.css";
</style>
