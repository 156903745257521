<template>
  <div class="answerExamBg">
    <div>
<!--      试题-->
      <div :class="examTimeShow||isShowRightAndAnalysis?'answerExamBGWd':'answerExamWd'">
        <exercisesList  :openType="2" :isShowTeacherComments="isShowTeacherComments" :isShowRightAndAnalysis="isShowRightAndAnalysis" :inputData="examPapersItemData" :subjectTypeData="subjectType" @queryDataFunc="getExamination"  :isShowSubmitBtn="isShowSubmitBtn" @interactiveData="examItemSubmitWorkAnswerData"  :isShowTeacherCorrection="ShowTeacherCorrection" :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore" :examData="selectExamData"
        ></exercisesList>
      </div>
    </div>
  </div>
</template>
<script>
  import {hmsFormatDate} from '@/utils/userCenterUtil'
  import {
    classNameList,
    collegeEntranceTemplateItemList,
    studentErroExercisesList,
    studentTemplateSave,
    teacherCheckExaminationDetail
  } from "@/api";
  import exercisesFB from "@/components/exercisesList/exercisesFB";
  import exercisesMC from "@/components/exercisesList/exercisesMC";
  import exercisesSAQ from "@/components/exercisesList/exercisesSAQ";
  import exercisesSC from "@/components/exercisesList/exercisesSC";
  import exercisesTF from "@/components/exercisesList/exercisesTF";
  import exercisesList from '@/components/exercisesList/index'
  import {findExamPapersExercisesStatistics, submitSchoolClassTestCenterAnswer} from "@/api/user";
  export default {
    data(){
      return{
        examinationArr:[],
        examPapersItemData:{},
        subjectType:'',
        collegeEntranceExamPapersId:'',
        isShowExamItem:false,
        isShowSubmitBtn:true,
        ShowTeacherCorrection:false,
        isShowRightAndAnalysis:false,
        isShowTeacherMCDecideScore:false,
        isShowTeacherComments:false,
        selectExamData:{},
        examArrDetail:{},
        examTimeShow:'',
        examinationId:''
      }
    },
    props:{

    },
    components:{
      exercisesFB,
      exercisesMC,
      exercisesSAQ,
      exercisesSC,
      exercisesTF,
      exercisesList
    },
    methods:{
      getExamination(queryData){
        let curre =  queryData.curre;
        let exercisesType = queryData.exercisesType;
        if(this.$route.query.userId){
          if(this.$route.query.examTypeShow==3){
            //  学生获取试卷详情(校内测试)
            teacherCheckExaminationDetail({examPapersTestCenterId:this.$route.query.id,
              userId:this.$route.query.userId,page:curre,size:100,exercisesType:exercisesType}).then(res=>{
              let newRecords = res.data.records;
              newRecords.forEach(record=>{
                let answerVo = {
                  answerFileName:record.answerFileName,
                  answerFileUrl:record.answerFileUrl,
                  mResult:record.mResult,
                  resultScore:record.resultScore,
                  mComments:record.resultEvaluate,
                  answerContent:record.answerContent,
                  errorBody:record.errorBody,
                };
                record['answerVo'] = answerVo;
              })
              this.examPapersItemData = res.data;
              if(this.examArrDetail.subjectType){
                this.subjectType=this.examArrDetail.subjectType
              }
              newRecords.forEach(item=>{
                if(item.mResult){
                  this.isShowRightAndAnalysis=true;
                  this.ShowTeacherCorrection=true;
                  this.isShowTeacherMCDecideScore=true;
                  this.isShowTeacherComments=true;
                  this.isShowSubmitBtn = false;
                }
              })
            })
          }else if(this.$route.query.examTypeShow==2){
            if(this.$route.query.synthesis){
              studentErroExercisesList({examPapersTestCenterId:queryData.examinationId?queryData.examinationId:this.examinationId,
                userId:this.$route.query.userId,page:curre,size:100,exercisesType:exercisesType}).then(res=>{
                let newRecords = res.data.records;
                newRecords.forEach(record=>{
                  let answerVo = {
                    answerFileName:record.answerFileName,
                    answerFileUrl:record.answerFileUrl,
                    mResult:record.mResult,
                    resultScore:record.resultScore,
                    mComments:record.resultEvaluate,
                    answerContent:record.answerContent,
                    errorBody:record.errorBody,
                  };
                  record['answerVo'] = answerVo;
                })
                this.examPapersItemData = res.data;
                if(this.examArrDetail.subjectType){
                  this.subjectType=this.examArrDetail.subjectType
                }
                newRecords.forEach(item=>{
                  if(item.mResult){
                    this.isShowRightAndAnalysis=true;
                    this.isShowSubmitBtn = false;
                    // this.ShowTeacherCorrection=true;
                    // this.isShowTeacherMCDecideScore=true
                  }
                })
              })
            }else{
              studentErroExercisesList({examPapersTestCenterId:this.$route.query.id,
                userId:this.$route.query.userId,page:curre,size:100,exercisesType:exercisesType}).then(res=>{
                let newRecords = res.data.records;
                newRecords.forEach(record=>{
                  let answerVo = {
                    answerFileName:record.answerFileName,
                    answerFileUrl:record.answerFileUrl,
                    mResult:record.mResult,
                    resultScore:record.resultScore,
                    mComments:record.resultEvaluate,
                    answerContent:record.answerContent,
                    errorBody:record.errorBody,
                  };
                  record['answerVo'] = answerVo;
                })
                this.examPapersItemData = res.data;
                if(this.examArrDetail.subjectType){
                  this.subjectType=this.examArrDetail.subjectType
                }
                newRecords.forEach(item=>{
                  if(item.mResult){
                    this.isShowRightAndAnalysis=true;
                    this.isShowSubmitBtn = false;
                    // this.ShowTeacherCorrection=true;
                    // this.isShowTeacherMCDecideScore=true
                  }
                })
              })
            }
          }
        }else{
          //历年真题,综合测试
          collegeEntranceTemplateItemList({collegeEntranceExamPapersId:this.collegeEntranceExamPapersId,page:curre,size:100,exercisesType:exercisesType}).then(res=>{
            this.examinationArr=res.data.records;
            let newRecords = res.data.records;
            newRecords.forEach(record=>{
              let answerVo = {
                answerFileName:record.answerFileName,
                answerFileUrl:record.answerFileUrl,
                mResult:record.mResult,
                resultScore:record.resultScore,
                mComments:record.resultEvaluate,
                answerContent:record.answerContent,
                errorBody:record.errorBody,
              };
              record['answerVo'] = answerVo;
            })
            this.examPapersItemData = res.data;
            if(this.examArrDetail.subjectType){
              this.subjectType=this.examArrDetail.subjectType
            }
            newRecords.forEach(item=>{
              if(item.mResult){
                this.isShowRightAndAnalysis=true;
                this.isShowSubmitBtn = false;
              }
            })
          })
        }

      },
      //提交
      examItemSubmitWorkAnswerData(data){
        if(data){
          let pushData = [];
          let nowTime = hmsFormatDate(new Date().getTime());
          for (var key in data){
            let examItemId = key;
            let answerContent = data[key].answerContent;
            if(answerContent){
              pushData.push({
                id:examItemId,
                answerContent:answerContent
              })
            }else{
              pushData.push({
                id:examItemId,
                exercisesBody:JSON.stringify(data[key].exercisesBody)
              })
            }
          }
          if(pushData.length>0){
            submitSchoolClassTestCenterAnswer({examPapersTestCenterId:this.examinationId?this.examinationId:this.collegeEntranceExamPapersId,userAnswerVoList:pushData,submitStartTime:nowTime}).then(res=>{
              if(res.code=="0"){
                this.$message({
                  message: '提交成功',
                  type: 'success'
                });
                this.$router.push('/topicList')
                // this.querySchoolClassExamPage(1);
                this.isShowExamItem = false;
                this.collegeEntranceExamPapersId = "";
                this.isShowRightAndAnalysis = false;
                this.isShowTeacherComments = false;

                this.selectExamData = {};
              }else{
                this.$message.error('提交失败');
              }
            })
          }
        }
      },
      //  获取考场id
      getStudentTemplateSave(examData){
        let data={
          createType:2,
          testPaperType:1,
          name:this.examArrDetail.name,
          examPapersTemplateId:this.$route.query.id
        }
        studentTemplateSave(data).then(res=>{
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          this.examinationId=res.data.id
          //查询试卷习题摘要信息
          findExamPapersExercisesStatistics({userId:userInfo.id,examPapersTestCenterId:res.data.id}).then(res=>{
            if(res.code=="0"&&res.data){
              this.selectExamData = examData;
              this.selectExamData.exercisesStatistics = res.data;
            }
          })
          this.getExamination({curre:1,exercisesType:"SC",examinationId:res.data.id});
        })
      },
      getExerciseArrList(examData){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        //查询试卷习题摘要信息
        findExamPapersExercisesStatistics({userId:userInfo.id,examPapersTestCenterId:this.$route.query.id}).then(res=>{
          if(res.code=="0"&&res.data){
            this.selectExamData = examData;
            this.selectExamData.exercisesStatistics = res.data
          }
        })
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',3);
      let examArrDetail=JSON.parse(sessionStorage.getItem('examArrDetail'));
      this.examArrDetail=JSON.parse(sessionStorage.getItem('examArrDetail'))
      this.collegeEntranceExamPapersId=this.$route.query.id;
      if(this.$route.query.synthesis){
        this.getStudentTemplateSave(examArrDetail);
      }
      if(this.examinationId||this.$route.query.record||this.$route.query.exam||this.$route.query.random){
        this.getExamination({curre:1,exercisesType:"SC"});
      }
      if(!this.$route.query.exam&&!this.$route.query.synthesis){
        this.getExerciseArrList(examArrDetail)
      }
      if(this.$route.query.exam){
        this.examTimeShow=this.$route.query.exam
      }
    },

  }
</script>
<style>
  .answerExamBg{
    padding-bottom: 200px;
    background: #F7F7F7;
    padding-top: 60px;
  }
  .el-radio__label{
    font-size: 16px!important;
  }
  /*.answerExamBg .el-table td{*/
  /*  border-bottom: 2px solid #b8b8b8;*/
  /*}*/
  .answerExamBg>div{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .answerExamSpan{
    width: 20%;
    background: white;
    padding: 20px;
  }
  .answerExamSpan>p{
    text-align: center;
    margin-bottom: 8px;
  }
  .answerExamSpan>p>span{
    color: #409EFF;
  }
  .answerExamWd{
    background: white;
    width: 81%;
  }
  @media (max-width:1200px) {
    .answerExamWd{
      width:73%;
      background: white;
    }
  }
  .answerExamBGWd{
    background: white;
    width: 100%;
  }
  .finished{
    text-align: center;
    margin-bottom: 20px;
    color: #999999;
  }
</style>
